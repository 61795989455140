$red: #5f0104;
$bright-red: #910000;
$black: #000000;
$white: #ffffff;
$gray: #3e3e3e;
$light-gray: #4d4d4d;
$dark-gray: #232323;
$grey: #a6a6a6;
$light-grey: #6e6e6e;
$light-blue: #72c9ff;

@font-face {
    font-family: "Pixellari Medium";
    src: url("./fonts/Pixellari.ttf") format("truetype"),
        url("./fonts/Pixellari.ttf") format("opentype"),
        url("./fonts/Pixellari.ttf") format("woff");
}

@font-face {
    font-family: "Helvetica Neu Bold";
    src: url("./fonts/Helvetica Neu Bold.ttf") format("truetype"),
        url("./fonts/Helvetica Neu Bold.ttf") format("opentype"),
        url("./fonts/Helvetica Neu Bold.ttf") format("woff");
}

@font-face {
    font-family: "HelveticaNeue Light";
    src: url("./fonts/HelveticaNeue Light.ttf") format("truetype"),
        url("./fonts/HelveticaNeue Light.ttf") format("opentype"),
        url("./fonts/HelveticaNeue Light.ttf") format("woff");
}

@font-face {
    font-family: "HelveticaNeue Medium";
    src: url("./fonts/HelveticaNeue Medium.ttf") format("truetype"),
        url("./fonts/HelveticaNeue Medium.ttf") format("opentype"),
        url("./fonts/HelveticaNeue Medium.ttf") format("woff");
}

@font-face {
    font-family: "Bebas Regular";
    src: url("./fonts/BEBAS.ttf") format("truetype"),
        url("./fonts/BEBAS.ttf") format("opentype"),
        url("./fonts/BEBAS.ttf") format("woff");
}