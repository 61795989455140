@import "./_variables.scss";

#teaser-page {
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    min-height: inherit;
}

#teaser-content-container {
    flex: 1;
    display: flex;
    padding: 150px 170px 100px 170px;
}

#teaser-content {
    min-width: 550px;
    flex: 1;
}

#machine-img-container {
    background-image: url("../static/image/teaser-page/machine-bg-light.png");
    background-repeat: no-repeat;
    background-size: contain;

    flex: 1;
    min-width: 750px;
    animation: machine-light 1s;
    position: relative;
    left: -80px;
}

#logo-container {
    margin: 20px 0;
    display: flex;
    align-items: center;
}

#drops-in-container {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    .drops-in-count {
        color: red;
        margin-left: 10px;
        font-size: 22px;
    }
}

#teaser-signup-form {
    margin-top: 8px;
    input {
        width: 275px;
        &::placeholder {
            text-align: center;
            color: $grey;
            font-size: 11px;
        }
    }

    button {
        margin-left: 10px;
        font-size: 11px;
        width: 175px;
        background-color: $gray;
        border-color: $light-gray;
    }
}

#policy-and-terms-label {
    color: $grey;
    #policy-and-terms-link {
        text-decoration: none;
    }
}

#teaser-footer-container {
    #footer-content {
        padding: 0 100px 30px 100px;
        display: flex;
        justify-content: space-between;

        #brands-container {
            margin-right: 100px;
        }
        .app-market-logo {
            width: auto;
            height: 35px;
            margin-left: 10px;
        }
    }
}

@keyframes machine-light {
    0%,
    30% {
        background-image: url("../static/image/teaser-page/machine-bg-dark.png");
    }
    100% {
        background-image: url("../static/image/teaser-page/machine-bg-light.png");
    }
}

@media (min-width: 1800px) {
    #teaser-content-container {
        zoom: 1.15;
    }
}

@media (min-width: 1920px) {
    #teaser-content-container {
        zoom: 1.3;
    }
}

@media (min-width: 2160px) {
    #teaser-content-container {
        zoom: 1.45;
    }
}

@media (min-width: 2880px) {
    #teaser-content-container {
        zoom: 1.6;
    }
}

@media (min-width: 4320px) {
    #teaser-content-container {
        zoom: 1.75;
    }
}

@media (min-width: 5760px) {
    #teaser-content-container {
        zoom: 1.9;
    }
}
