@import "./_variables.scss";

#temp-page {
    background-image: url("../static/image/Websitebg.jpg");
    height: 100vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    background-position-y: center;
    background-color: black;

    .main-text {
        color: $white;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
            font-size: 50px;
            font-weight: normal;
        }
    }
}
