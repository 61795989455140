@import "./_variables.scss";

#detail-page {
    padding: 90px 70px;
}

#detail-page-header {
    display: flex;
    align-items: center;
    #detail-page-year {
        margin-left: 30px;
        font-size: 85px;
        font-weight: normal;
        -webkit-text-stroke: 2px $red;
        color: rgba(0, 0, 0, 0);
    }
}
#detail-page-logo {
    display: flex;
    align-items: flex-end;
    margin-bottom: 6px;
    img {
        height: 50px;
        margin-right: 12px;
    }
    h5 {
        line-height: 22px;
        margin-bottom: 0;
        font-weight: normal;
    }
}

.logo-small {
    height: 30px;
}

.tile-wrapper {
    max-width: 1300px;
    position: relative;
    margin: 40px 0;
    display: grid;
    grid-template-columns: repeat(2, 480px);
    grid-gap: 8px;
    grid-template-areas:
        "tile-1 tile-2 . ."
        "tile-1 tile-3 . ."
        "tile-4 tile-5 . ."
        "tile-6 tile-7 . .";
}

.tile-content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .tile-status {
        min-width: 85px;
    }
}

.tile {
    display: flex;
    height: 100%;
    position: relative;
    .tile-img-container {
        cursor: pointer;
        padding: 20px 25px;
        width: 170px;
        display: flex;
        align-items: center;
        .tile-img {
            width: 100%;
            height: auto;
        }
    }
    .tile-content {
        flex: 1;
        padding: 15px;
    }
}

.tile-item {
    z-index: 1;
    background-color: $gray;
    color: $white;
}

.tile-item-1 {
    grid-area: tile-1;
    background-color: $white;
    color: $black;
}

.tile-item-2 {
    grid-area: tile-2;
}

.tile-item-3 {
    grid-area: tile-3;
}

.tile-item-4 {
    grid-area: tile-4;
}

.tile-item-5 {
    grid-area: tile-5;
}

.tile-item-6 {
    grid-area: tile-6;
}

.tile-item-7 {
    grid-area: tile-7;
}

.view-on-btn {
    background-color: $gray;
    color: $white;
    border: none;
    border-radius: 8px;
    padding: 8px 15px;
    display: block;
    width: 100%;
    font-size: 10px;
    &:focus {
        outline: none;
    }
    img {
        height: 18px;
        margin-left: 8px;
    }
    &:hover {
        background-color: $dark-gray !important;
    }
}

.detail-page-body {
    margin-top: 60px;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
}

.article-container {
    display: flex;
    align-items: center;
}

.bg-element {
    min-width: 240px;
}

.bg-element-1 {
    display: flex;
    margin-right: 40px;
    font-size: 12px;
    img {
        width: 80px;
    }
}

.bg-element-2 {
    margin-left: 40px;
    align-self: flex-end;
    img {
        width: 100%;
        max-width: 210px;
    }
}

.legend-container {
    position: relative;
    display: inline-block;
    top: -25px;

    .legend-img {
        width: 110px;
        position: absolute;
        right: -100px;
        bottom: -100px;
    }
}

.fact-bubble {
    position: relative;
    display: inline-block;
}

.fact-text {
    position: absolute;
    color: $black;
    top: 0;
    bottom: 0;
    right: 59px;
    left: 0;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 20px;
}

.audio-control {
    width: 14px !important;
    height: auto;
}

#centipede-bg-img-1 {
    position: absolute;
    top: 150px;
    left: -155px;
    width: 85px;
    z-index: 0;
}

#centipede-bg-img-2 {
    position: absolute;
    top: 200px;
    right: -200px;
    width: 190px;
    z-index: 0;
}

#centipede-bg-img-3 {
    position: absolute;
    top: 0px;
    right: -190px;
    width: 60px;
    z-index: 0;
}

#pong-bg-img-1 {
    position: absolute;
    top: 130px;
    left: -155px;
    width: 60px;
    z-index: 0;
}

#pong-bg-img-2 {
    position: absolute;
    top: -45px;
    left: -85px;
    width: 540px;
    z-index: 0;
}

#pong-bg-img-3 {
    position: absolute;
    top: 280px;
    left: -70px;
    width: 25px;
    z-index: 0;
}

#pong-bg-img-4 {
    position: absolute;
    top: 40px;
    right: -120px;
    width: 60px;
    z-index: 0;
}

.header-logo {
    height: 30px;
    transition-duration: 0.4s;
    -webkit-filter: contrast(0.5) grayscale(1) brightness(0.5);
    filter: contrast(0.5) grayscale(1) brightness(0.5);
    &:hover {
        -webkit-filter: contrast(1) grayscale(0) brightness(1);
        filter: contrast(1) grayscale(0) brightness(1);
    }
}

.comming-soon {
    background-color: $gray;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
