@import "./_variables.scss";

#app-container {
    background: linear-gradient(180deg, $black 60%, $red 100%);
}

#app-container-2 {
    background: $black;

    display: flex;
    flex-direction: column;
}

#teaser-container {
    background: $black;
}

#app-container,
#teaser-container,
#app-container-2 {
    min-height: 100vh;
    color: $white;
    padding: 0;
}

.red-haze {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50vh;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), $red 64%);
}

#atari-watermark-bg {
    background-image: url("../static/image/atariperspectivewatermark.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 70px;
    background-attachment: fixed;
    background-size: contain;

    flex: 1;
    display: flex;
    position: relative;

    #bottom-bg {
        flex: 1;
        position: relative;
        background-image: url("../static/image/bottom-bg.png");
        background-repeat: no-repeat;
        background-position-y: calc(100% + 3px);
        background-size: calc(100% + 8px);
        padding-bottom: 150px;

        overflow-x: auto;
    }
}

.text-black {
    color: $black;
}

.text-red {
    color: $red;
}

.text-red-red {
    color: red;
}

.text-grey {
    color: $grey;
}

.text-bright-red {
    color: $bright-red;
}

.bg-grey {
    background-color: $grey !important;
}
.bg-light-grey {
    background-color: $light-grey !important;
}

.img-blur {
    filter: blur(5px);
    -webkit-filter: blur(5px);
}

.text-xs {
    font-size: 8px;
}

.text-small {
    font-size: 12px;
}

.text-medium {
    font-size: 16px;
}

.text-large {
    font-size: 18px;
}

.bebas-regular {
    font-family: "Bebas Regular";
    word-spacing: 6px;
    letter-spacing: -1px;
}

.bebas-sm-spacing {
    word-spacing: 4px;
}

.pixellari-medium {
    font-family: "Pixellari Medium";
}

#helvetica-neu-bold {
    font-family: "Helvetica Neu Bold";
}

.helvetica-neu-medium {
    font-family: "HelveticaNeue Medium";
}

.helvetica-neu-light {
    font-family: "HelveticaNeue Light";
}

#navbar {
    z-index: 2000;
}
.no-wrap {
    white-space: nowrap;
}

#footer {
    margin-top: 30px;
}
#quidd-logo {
    margin-top: 8px;
    margin-right: 20px;
    width: 70px;
    height: auto;
}
#google-play-logo {
    margin: 12px 0;
}
#app-store-logo,
#google-play-logo {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100px;
    height: auto;
}

.privacy-policy {
    margin-left: auto;
    margin-right: auto;

    h4 {
        font-size: 16px;
        margin-bottom: 14px;
        font-weight: bold;
    }
    h5 {
        font-size: 13px;
        margin-bottom: 4px;
        font-weight: bold;
    }
    h6 {
        font-size: 10px;
        margin-bottom: 0;
        font-weight: bold;
    }
    p {
        font-size: 10px;
        margin-bottom: 12px;
    }
    #privacy-policy-close {
        color: $gray;

        position: absolute;
        right: -15px;
        top: -25px;
    }
}

.hide-btn {
    background: none;
    border: none;
    outline: none;
    &:focus {
        outline: none;
    }
}

#quidd-x-blockparty {
    display: inline-block;
    img {
        margin: 0 20px;
    }
}

#brands-container {
    display: flex;
    align-items: center;
    img {
        margin: 0 20px;
        opacity: 0.5;
        transition: 0.4s;
        &:hover {
            opacity: 1;
        }
    }
}

.nft-description-modal {
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: $white;
    opacity: 0.9;
    border: none;
    position: relative;
    z-index: 2000;
    height: auto;
    background-color: black !important;
}

.nft-description-modal-overlay {
    position: relative;
    z-index: 2000;
}

#signup-form-group {
    display: flex;
    min-width: 640px;
    #email-input-container {
        label {
            font-size: 12px;
        }
        flex: 1;
        margin-right: 10px;
        input {
            &::placeholder {
                text-align: center;
            }
        }
    }
    #signup-btn-container {
        flex: 1;
        button {
            width: 100%;
            background-color: $gray;
            color: $white;
            border-color: $gray;
            font-size: 12px;
        }
    }
}

#signup-form-container {
    margin-top: 24px;
    display: flex;
    align-items: flex-start;
    #capture-future {
        margin: 0 15px;
        span {
            display: block;
            white-space: nowrap;
            text-align: right;
            line-height: 16px;
        }
    }

    #social-links {
        padding-top: 4px;
        padding-left: 20px;
        white-space: nowrap;
        a {
            margin: 0 10px;
            img {
                height: 16px;
                opacity: 0.5;
                transition: 0.4s;
                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}

#footer-2 {
    padding: 30px 40px 0 40px;
    overflow-x: auto;
    background-color: rgb(22, 0, 0);
    #footer-contents {
        display: flex;
        justify-content: space-between;
    }
}

.opaque {
    opacity: 0.35;
}

.modal-content {
    background-color: rgba(0, 0, 0, 0) !important;
    border-width: 0 !important;
}

.modal-dialog {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

@media (min-width: 2880px) {
    .modal-dialog {
        top: 550px !important;
    }
}

.slide-pane__overlay {
    z-index: 1000;
}

.text-decoration-none {
    text-decoration: none;
}
