@import "./_variables.scss";

#landing-page-container {
    background-image: url("../static/image/langding-page-bg.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100% 700px;
    padding-top: 80px;
    overflow-x: hidden;
}

#timeline-container {
    padding-bottom: 50px;
    overflow-x: auto;
    #timeline-title {
        letter-spacing: 3px;
        text-align: right;
        font-size: 50pt;
        padding-bottom: 30px;
        white-space: nowrap;
        color: rgba(0, 0, 0, 0);
        -webkit-text-stroke: 1px $grey;
    }
}

#timelinelist-container {
    padding-top: 30px;
    position: relative;
    display: inline-block;

    #centepede-img {
        position: absolute;
        top: -100px;
        right: -170px;
        img {
            width: 340px;
        }
    }

    #mushroom-img-sm {
        position: absolute;
        top: -100px;
        left: -60px;
        img {
            width: 70px;
        }
    }

    #mushroom-img {
        position: absolute;
        top: 0%;
        left: -200px;
        img {
            width: 140px;
        }
    }

    #spider-img {
        position: absolute;
        top: 50%;
        right: -170px;
        img {
            width: 230px;
        }
    }

    #player-shot-img {
        position: absolute;
        top: 48%;
        left: -150px;
        img {
            width: 120px;
        }
    }

    #flea-img {
        position: absolute;
        bottom: -20px;
        left: 100px;
        img {
            width: 140px;
        }
    }

    .timeline-red-border {
        border-style: solid;
        border-color: $red;
    }

    .timeline-gray-border {
        border-style: solid;
        border-color: $gray;
    }

    .timeline-line-center {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 8px;
        border-left: 1px solid $red;
        border-right: 1px solid $red;
        background-color: $gray;
    }

    .timeline-line-left {
        @extend .timeline-red-border;
        border-width: 0 1px 1px 0;
        border-bottom-right-radius: 30px;
        width: 30px;

        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(50% - 33px);

        &::before {
            content: "";
            @extend .timeline-gray-border;
            border-width: 0 6px 6px 0;
            border-bottom-right-radius: 30px;

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        &::after {
            content: "";
            @extend .timeline-red-border;
            border-width: 0 1px 1px 0;
            border-bottom-right-radius: 30px;

            position: absolute;
            top: 0;
            left: 0;
            right: 6px;
            bottom: 6px;
        }
    }

    .timeline-line-right {
        @extend .timeline-red-border;
        border-width: 0 0 1px 1px;
        border-bottom-left-radius: 30px;
        width: 30px;

        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(50% + 11px);

        &::before {
            content: "";
            @extend .timeline-gray-border;
            border-width: 0 0 6px 6px;
            border-bottom-left-radius: 30px;

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        &::after {
            content: "";
            @extend .timeline-red-border;
            border-width: 0 0 1px 1px;
            border-bottom-left-radius: 30px;

            position: absolute;
            top: 0;
            left: 6px;
            right: 0;
            bottom: 6px;
        }
    }
}

.timelinecard-container {
    display: flex;
    width: 525px;
    height: auto;
    align-items: center;
    margin-top: -20px;

    .timelinecard-year {
        font-size: 32px;
        color: red;
    }
    button {
        margin: 0 35px;
        .timeline-img {
            width: 165px;
        }
    }
}

.timelinecard-right {
    flex-direction: row-reverse;
    margin-left: 550px;
    text-align: left;
}

.timeline-row {
    margin-top: 10px;
}

#footer-padding {
    padding-top: 50px;
    padding-bottom: 20px;
}

.collection-container {
    text-align: center;
    padding: 30px 0;
    white-space: nowrap;
    overflow-x: auto;

    .collection-container-hover {
        box-shadow: 0 0 9px 9px rgba(112, 112, 112, 0.5), 0 0 9px 9px rgba(112, 112, 112, 0.5) !important;
        transition: ease-in-out 0.3s;
    }

    .card-container {
        width: 150px;
        height: 250px;
        overflow: hidden;
        margin: 0 10px;
        display: inline-block;
        position: relative;

        .card-image {
            width: 100%;
            height: 100%;
        }

        .card-description-container {
            position: absolute;
            bottom: 20px;
            left: 0;
            right: 0;
            z-index: 1;
        }

        .animate-text-size {
            transform: scale(1.15);
        }

        .card-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.75);
            transition: ease-in-out 0.3s !important;
        }

        .card-desc-1 {
            transition: transform 0.3s;
        }

        .card-desc-2 {
            height: 12px;
        }
    }
}

.collection-title {
    text-align: center;
    font-size: 32pt;
    font-family: "Pixellari Medium";
}

.collection-subtitle {
    text-align: center;
    font-size: 11pt;
    .quidd-logo {
        width: 45px;
        margin-left: 5px;
    }
}

.collection-description-title {
    font-size: 20pt;
}

.collection-description {
    font-family: "Pixellari Medium";
    p {
        max-width: 800px;
    }
}

.check-back-overlay {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    position: relative;
}

#nft-color {
    color: $light-blue;
}

#hero-container {
    overflow-x: hidden;
    overflow-y: hidden;
    text-align: center;
}

#hero-content {
    padding-top: 120px;
    display: inline-flex;
    align-items: flex-start;

    #hero-title-container {
        z-index: 2;
    }
}
#hero-title-content {
    position: relative;
    padding-top: 30px;

    h1 {
        font-weight: normal;
        font-size: 68px;
        position: absolute;
        top: 70px;
    }
    #history {
        font-size: 200px;
        color: rgba(0, 0, 0, 0);
        -webkit-text-stroke: 1px red;
        letter-spacing: 3px;
        &:after {
            content: "HISTORY";
            position: absolute;
            left: 0;
            right: 0;
            bottom: -30px;
            font-size: 158px;
            -webkit-text-stroke: 1px $red;
        }
    }

    #capsule-title {
        position: absolute;
        font-size: 25px;
        right: -4px;
        top: 234px;
        text-align: left;
        span {
            display: block;
            white-space: nowrap;
            line-height: 25px;
        }
    }
}

#hero-text-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
    p {
        width: 480px;
        text-align: left;
    }
}

#cabinet-container {
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    margin-left: 20px;

    #centipede-cabinet {
        margin-right: 50px;
        background-image: url("../static/image/landing-page-2/CentipedeCabinetDark.png");
        &:hover {
            background-image: url("../static/image/landing-page-2/CentipedeCabinetLight.png");
            #centipede-cabinet-logo {
                opacity: 1;
            }
        }
    }
    #pong-cabinet {
        background-image: url("../static/image/landing-page-2/PongCabinetDark.png");
        &:hover {
            background-image: url("../static/image/landing-page-2/PongCabinetLight.png");
            #pong-cabinet-logo {
                opacity: 1;
            }
        }
    }
    #centipede-cabinet,
    #pong-cabinet {
        z-index: 1;
        transform: scale(0.9);
        transition: 0.4s;
        .cabinet-hidden {
            visibility: hidden;
        }
    }

    #centipede-cabinet-logo {
        bottom: 90px;
    }
    #pong-cabinet-logo {
        top: -80px;
    }
    #pong-cabinet-logo,
    #centipede-cabinet-logo {
        position: absolute;
        left: 0;
        right: 0;
        opacity: 0;
        transition: opacity 0.4s linear;
    }

    #red-haze {
        position: absolute;
        bottom: -90px;
        z-index: 0;
    }
}

@media (max-width: 1180px) {
    #hero-container {
        overflow-x: auto;
    }
}
